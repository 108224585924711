@import '../../../../commonStyles.scss';

.fileList {
    margin-bottom: 10px;
    float: left;
    width: 100%;
    cursor: pointer;

    .fileIcon {
        float: left;
        margin-right: 5px;
        margin-top: 2px;
    }

   .titleMethod{
        color: $primary;
        float: left;
        width: calc(100% - 30px);
        font-size: 16px;
    }
}

.docs {
    padding: 10px 60px;
    background-color: $light;

    @include mobileDevices {
        padding: 30px;
    }
    .heading {
        font-size: $fs-18;
        font-family: $font-bold;
        margin-bottom: 10px;
    }
}