@import '../../../commonStyles.scss';

.ant-notification,
.ant-notification-notice {
  padding: 10px;
  width: 570px;
  border-radius: 4px;

  .ant-notification-notice-message {
    .ant-notification .ant-notification-notice .ant-notification-notice-message {
      color: #fff !important;
    }

    .ant-notification-notice-success {
      color: #fff;
    }

    color: $light;
  }

  &.ant-notification-notice-success {
    background-color: $green;
    color: $light;
    font-size: 14px;

    .ant-notification-notice-icon-success {
      svg {
        color: $light;
      }
    }

    svg {
      color: $light;
    }
  }

  .ant-notification-notice-description {
    float: left;
    width: 78%;
  }


  .ant-notification-notice-btn {
    float: right;
    margin-top: -20px;
  }
}

.successText {
  .ant-notification-notice-content {
    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        color: #fff;
      }
      .ant-notification-notice-description {
        color: #fff;
      }
    }
  }
}

