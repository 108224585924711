.jsonView{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 80%;
    cursor: pointer;
}
.payloadView{
    white-space: pre-line;
}
.approveBtn{
    background-color: #367E7F;
    color: #fff !important;
}
.payload {
    cursor: pointer;
    white-space: nowrap;
}
.p5 {
    padding : 5px !important;
}
.ApprovalWrapper{
    display: flex;
    .labelView{
        width: 20%;
    }
    .splitView{
        width: 5%;
    }
    .valueView{
        width: 75%;
    }
}
.breakView{
  border-bottom: 1px solid #ddd;
  margin-top: 5px;
}