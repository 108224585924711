@import '../../../../commonStyles.scss';

.headerContent {
    padding: 40px 60px;
    background-color: $light;

    @include tablet {
        padding: 40px 30px;
    }
    .title {
        font-size: 40px;
        margin-bottom: 20px;
        font-family: $font-bold;
    }

    .headerContent-body {
        font-size: 24px;
        font-family: $font-medium;
    }
}