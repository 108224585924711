@import '../../../../commonStyles.scss';

.badgeDescriptor {
  padding: 40px 60px;

  .whiteBadge {
    background-color: $light;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-family: $font-medium;
    font-size: $fs-16;
  }

  .heading {
    font-family: $font-medium;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .buttonStyles {
    margin-top: 25px;
  }

  .copyIcon {
    cursor: copy;
    float: left;
    margin-top: 7px;
  }  

  .borderBlock {
    border: 1px solid #D2D5D5;
    padding: 15px;
  }

  .textareaBlock{
    width: 96%;
  }
}