@import '../../../../../../../commonStyles.scss';

.integrationStatus {
  padding: 36px;

  .saveChanges {
    margin-top: 50px;

    @include mobileDevices {
      margin: 10px 0;
    }
  }

  .leftMoreSpace {
    margin-left: 2px;
    cursor: pointer;
    display: inline-block;
    margin-top: 5px;
  }

  .viewRight {
    margin-top: 7px;
  }

  .tabsHeading {
    font-size: $fs-18;
    font-family: $font-bold;
    margin-bottom: 10px;
  }

  .viewingLabel {
    margin-right: 10px;
  }

  .editableText {
    border: none;
    padding: 0;
  }

  @include mobileDevices {
    padding: 40px;
  }

  .buttonSpace {
    margin-right: 10px;
  }

  .statusBox img{
    margin-right: 5px;
    float: left;
  }

  .imgElement {
    margin-right: 5px;
  }

  .resourcesData {
    img {
      margin-right: 5px;
    }
  }

  .resourcesimg {
    margin-right: 5px;
  }

  .buttonTools {
    margin: 10px 0;

    .outlineButton {
      margin-bottom: 15px;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  .boldText {
    font-family: $font-bold;
  }

  .allStatus {
    width: 200px;
    margin-bottom: 10px;
  }

  .textBlock {
    display: inline-block;
  }

  .heading {
    font-size: 40px;
    font-family: $font-bold;
    margin-bottom: 10px;
  }

  .cardBlockContainer {
    padding: 10px;
    background-color: $light;
    margin-bottom: 20px;
  }

  .toggleButton {
    display: inline-block;
    margin-right: 15px;
  }

  .borderedContainer {
    border: 1px solid #D2D5D5;
    padding: 10px;
    font-size: 16px;
    font-family: $font-medium;
  }

  .cardList {
    .form-item {
      margin-bottom: 20px !important;
    }
  }

  .contactInfo {
    width: 100%;
  }

  .btnActions {
    button {
      &:first-child {
        margin-right: 10px;
      }
      padding: 6px 15px;
    }

    .acceptBtn {
      border: 1px solid #036A3C;
      background-color: #E6F3ED;
    }
    
    .rejectBtn {
      border: 1px solid #AE2116;
      background-color: #FBEAE8;
    }
  }

  .leftSpace {
    margin-left: 5px;
  }

  .editIcon {
    margin-right: 5px;
    float: left;
    margin-top: 1px;
  }

  .headerblock {
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;

    .subHeading {
      margin-bottom: 10px;
      font-size: $fs-18;
      font-family: $font-medium;
    }
  }

  .boxList {
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }

  .cardList {
    margin-bottom: 10px;

    &:not(.noBorder) {
      margin-bottom: 10px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 10px;

      > div {
        margin-bottom: 5px;
      }
    }

    &:last-child {
      margin-bottom: 0;
      div:last-child {
        border-bottom: none;
      }
    }
  }

  .mailList {
    margin-top: 10px;
  }

  .account,
  .mail {
    margin-right: 5px;
    max-width: 20px;
  }

  .searchbar {
    width: 447px;
    margin-bottom: 20px;
    border-radius: 4px;

    &::placeholder {
      color: #757575;
    }

    @include mobileDevices {
      width: 100%;
    }
  }

  .boldHeading {
    font-family: $font-bold;
    font-size: 18px;
    margin: 10px 0;
  }

  .registrationStatus {
    width: 100%;
  }

  .copyIcon {
    cursor: copy;
  }


  .infoIcon {
    color: #1976d2;
    margin-left: 8px;
    margin-top: 1px;
    cursor: pointer;
  }

  .innerTabs {
    margin-bottom: 15px;

    .tabList {
      width: calc(20% - 12px);
      margin-right: 15px;
      display: inline-block;
      background-color: $light;
      cursor: pointer;
      border-radius: 2px;
      line-height: 28px;

      &.active {
        box-shadow: 2px 2px 2px #ccc;
      }

      @include Desktop1200 {
        width: calc(23.6% - 3px);
      }

      @include mobileDevices {
        margin-bottom: 15px;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      .colorBlock {
        height: 6px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      .boxshadow {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        line-height: 2;
      }

      &.Pending {
        .colorBlock {
          background-color: #F39200;
        }

        .boxshadow {
          background: rgba(242,145,0,0.4);
        }
      }
      
      &.Rejected {
        .colorBlock {
          background-color: #AE2116;
        }

        .boxshadow {
          background: rgba(174,33,22,0.6);
        }
      }

      &.InProgress {
        .colorBlock {
          background-color: #008000;
        }
        .boxshadow {
          background: rgba(0,128,0,0.4);
        }
      }

      &.ReadyforProduction {
        .colorBlock {
          background-color: #757575;
        }
        .boxshadow {
          background: rgba(117,117,117,0.4);
        }
      }

      &.FullProduction {
        .colorBlock {
          background-color: #1572C2;
        }
        .boxshadow {
          background: rgba(21,114,194,0.4);
        }
      }

      .listBlock {
        padding: 5px 8px;
        font-size: 12px;
        font-family: $font-bold;
      }
    }   
  }

  .card {
    padding: 15px 25px;
    border-radius: 4px;
    border: none;

    .borderedBtn {
      margin-top: 5px;
    }

    .addIcon {
      margin-right: 5px;
      float: left;
      margin-top: 6px;
    }

    .leftDoc {
      width: 70%;
      margin-top: 8px;

  
      .titleDoc {
        margin-left: 5px;
      }
    }

    .fileListIcon {
      float: left;
      margin-top: 3px;
    }

    .toolbox {
      display: flex;
    }

    .downloadbox {
      border: 1px solid #BBBFBF;
      margin-right: 10px;
    }

    .trashbox {
      background-color: #FBEAE8;
    }
  }

  .resources {
    color: #398284;

    .resources-block{
      margin-bottom: 10px;
    }
  }

  .link {
    color: #398284;
  }

  .support {
    margin-top: 10px;
  }

  .labelText {
    font-family: $font-medium;
    color: $dark;
    margin-bottom: 8px;
  }

  .dateBlock {
    .ant-picker {
      width: 85%;

      @include mobileDevices {
        width: 100%;
      }
    }
  }

  .viewButton {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .dateBlock {
    display: flex;
    margin: 15px 0;
    flex-wrap: wrap;

    & > .dateList {

      .startDate {
        margin-top: 3px;
      }

      &:first-child {
        width: 25%;
      }

      width: 37%;

      @include mobileDevices {
        width: 100%;

        &:first-child {
          width: 100%;
        }
      }
    }
  }

  .textBlock {
    margin-bottom: 10px;

    p {
      &:first-child {
        color: #727777;
      }
    }
  }

  .ant-collapse-header-text {
    font-size: 20px;
    color: #757575;
    font-family: $font-regular !important;
  }

  .tickIcon {
    margin-right: 15px;
    float: left;
  }
  .customAccordion .ant-collapse .ant-collapse-item  {
    &.ant-collapse-item-active {
      .ant-collapse-header {
        .ant-collapse-arrow {
          svg {
            display: none;
          }

          &::after {
            content: url('../../../../../../../assets/images/expand_more.png') !important;
            top: -9px;
            left: -11px;
          }
        }

        .ant-collapse-expand-icon {
          right: 30px;
          transform: rotate(180deg);
          top: 10px; 
        }
      }
    }
  }
  .customAccordion .ant-collapse .ant-collapse-item .ant-collapse-header  {
    padding: 16px 17px;
    .ant-collapse-expand-icon {
      top: 27px;
      right: 30px;
    }

    .ant-collapse-arrow {
      svg {
        display: none;
      }
  
      &::after {
        transform: rotate(180deg);
        top: -8px;
        left: 0;
        content: url('../../../../../../../assets/images/expand_more.png') !important;
      }
    }
  }

  .cardContainer {
    background-color: $dark;
  }

  .cardListBlock {
    background-color: $light;

    .heading {
      font-family: $font-bold;
      font-size: 18px;
    }

    .status {
      font-family: $font-medium;
      margin-right: 10px;
    }
  }

  .cardHeader{
    padding: 18px;
    border-bottom: 1px solid #e5e5e5;
  }

  .accordionView {
    padding: 25px;

    .customAccordion {
      border: 1px solid #E8EAEA;
      margin-top: 0;
    }
  }

  .roundButton {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: $fs-18;
    background-color: $primary;
    display: inline-block;
    margin-right: 10px;
    color: $light;
    border-radius: 50%;
  }
}
.rejectButton {
  &.viewButton {
    border: 1px solid #AE2116;
    color: #AE2116;
    background-color: #FBEAE8;
  }
}
.rejectSubscription {
  .ant-modal-body {
    color: $dark;
  }

  .para {
    margin-bottom: 20px;
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: #DDDFDF;
    margin-bottom: 20px;
  }

  .requiredLineText {
    margin-right: 5px;
  }

  .requiredText {
    color: #757575;
    font-size: 12px;
  }

  .headingText {
    font-family: $font-medium;
  }

  .labelText {
    margin-bottom: 5px;
  }
}

.required {
  color: red;
}
