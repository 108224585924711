.borderBlock {
    border: 1px solid #D2D5D5;
    padding: 15px;
    border-radius: 4px;
    .title{
        margin-top: 15px;
        font-size: 24px;
        font-weight: bold;
        height: auto;
    }
    .description{
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 16px;
        height: auto;
        span{
            font-weight: bold;
        }
    }
}