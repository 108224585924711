@import './commonStyles.scss';

*,::after,::before {
 box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "HelveticaNeue",sans-serif !important;
  background-color: #Efefef;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  overflow-x: hidden;

  p {
    margin: 0;
  }
}

.clearfix {
  &::after {
    clear: both;
    display: block;
    content: '';
  }
}

.float-left {
  float:left;
}

.float-right {
  float: right;
}

.splitRow {
  display: flex;

  .splitCol {
    &:first-child {
      width: 19.5%;

      @include tablet {
        position: absolute;
        width: 100%;
        z-index: 99;
        height: 100%;
      }
    }

    &:last-child {
      width: 80.5%;

      @include tablet {
        width: 100%;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

:where(.css-dev-only-do-not-override-1n7nwfa)[class^="ant-col"], :where(.css-dev-only-do-not-override-1n7nwfa)[class*=" ant-col"] {
  font-family: "Helvetica",sans-serif !important;
}

:where(.css-dev-only-do-not-override-j0nf2s)[class^="ant-col"], :where(.css-dev-only-do-not-override-j0nf2s)[class*=" ant-col"] {
  font-size: 1rem !important;
}

.mandatoryMsg {
  color: red;
  margin-top: 5px;
  font-size: 13px;

}

.normalButton[disabled] {
  opacity: 0.7;
}
.titleBadge {
  background-color: #fff;
  color: $primary;
  border: 1px solid $primary;
  display: inline-block;
  margin-right: 10px; 
  padding: 3px 9px;
  border-radius: 4px;
  font-family: $font-regular;
  font-size: 1rem;
  line-height: 1.5;
}
