@import '../../../../commonStyles.scss';

.bannerBlock {
    background-color: $light;
    display: flex;

    .headingIcon {
      float: left;
      margin-right: 4px;
      margin-top: 8px;

   
    }

    .headline {
      &:hover {
        .editable.headingIcon {
          margin-top: 18px;
          margin-right: 10px;
        }
      }
    }

    .btnGrp {
      margin-top: 25px;

      button {
        padding: 9.5px 25px;
        border-bottom: 2px solid $primary-200;

        &:first-child {
          padding: 7px 25px;
        }

        &:last-child {
          margin-left: 15px;
        }
      }
    }

    .videoBlock {
      object-fit: cover;
    }

    .watch {
      cursor: pointer;
    }

    @include tablet {
      flex-wrap: wrap;
    }
  
    .subText {
      margin-bottom: 10px;
      font-size: 16px;
      color: $dark-100;
      white-space: pre-line;
      .editableTools {
        display: none;
      }
      &:hover {
        .editableTools {
          display: block;
        }
      }
    }
  
    .contentBlock {
      width: 50%;
      padding: 72px 30px;
      position: relative;

      @include LargeDesktop1900 {
        width: 50%;
      }

      @include tablet  {
        width: 100%;
        order: 2;
      }

      @include mobileDevices {
        padding: 50px 30px;
      }
  
      .bannerImage {
          max-width: 300px;
          margin-bottom: 15px;

          @include mobileDevices {
            max-width: 100%;
          }
      }
  
      .headline {
          font-family: $font-bold;
          font-size: $fs-18;
          margin-bottom: 10px;
          .editableTools {
            display: none;
          } 
          &:hover {
            .editableTools {
              display: block;
            } 
          }

          &.extraLarge {
            .headingText {
              font-size: 52px;
              font-family: $font-bold;
              line-height: 1;
            }
          }

          &.large {
            font-size: 50px;
            font-family: $font-bold;
            line-height: 1;
          }
      }
    }

    .videoContainer {
      position: relative;
      width: 50%;

      .editableTools {
        display: none;
      }

      &.editableContainer:hover {
        .editableContent {
          opacity: 0.5;
        }
        .editableTools {
          display: block;
        }
      }

      .videoBlock {
        height: 422px;
      }
      
      .editableTools {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-22%, -50%);
        width: 165px;
        text-align: center;

        .normalButton {
          padding: 3px 15px;
          margin-right: 10px;
          line-height: 1.5;
          border: 1px solid $primary;
        }
      }

      @include LargeDesktop1900 {
        width: 50%;
      }

      @include tablet {
        width: 100%;
        height: 325px;
        order:1;
      }

      @include mobileDevices {
        height: 180px;
      }

      .imageBlock {
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        

        @include tablet {
          background-size: cover;
        }
      }

      .playImg {
        position: absolute;
        transform: translate(-50%, -50%);
        margin-left: 4px;
        left: 56%;
        top: 51%;
        width: 200px;
        img {
          float: left;
        }
        .watchText{
          font-family: $font-medium;
          font-size: 22px;
          color: $light;
          float: left;
        }
      }


    }
  }
  .editableTools {
    margin-bottom: 5px;

    .normalButton {
      padding: 3px 15px;
      margin-right: 10px;
      line-height: 1.5;
      border: 1px solid $primary;
    }

    .editImage {
      margin-right: 5px;
    }
  }
  .editBannerFooter {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
    gap: 15px;

    button {
      width: 100%;
    }
    .normalButton.viewButton {
      margin-right: 0;
    }
  }

  .imageIcon {
    margin-right: 5px;
    margin-top: -3px;
  }

.bannerImageModal {
  .ant-upload-select {
    width: 100%;
  }

  .ant-modal-footer {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

  .ant-btn {
    width: 100%;
    font-family: $font-bold;
  }

  .imageContainer {
    border: 1px solid #DDDFDF;
    background-color: #FAFAFA;
    margin-bottom: 10px;

    .imageList {
      display: flex;

      .imageName {
        display: flex;
        align-items: center;
        width: 45%;

        span {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .imageBlock {
    height: 88px;
    border-radius: 4px;
    margin: 10px;
    width: 55%;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border: 1px solid #ccc;
    }


    .imagePreview {
      max-height: 100%;
      width: 100%;
    }
  }

  .ant-upload-list-item-container {
    display: none;
  }
}

.editModalPopup {
  .ant-modal-footer {
    margin-top: 0;
    border: none;
    padding-top: 0;
  }
  .editableModal{
    .labelText {
      font-family: $font-medium;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }

  .noteText {
    font-size: 12px;
    margin-top: 5px;
  }
}
.labelText {
  font-family: $font-medium;
  margin-bottom: 5px;
  margin-top: 5px;
}