@import '../../../../../commonStyles.scss';

.header {
  background-color: $dark;
  padding: 8px 15px 12px;
  color: $light;
  position: sticky;
  top: 0;
  z-index: 999;

  &.editable {
    padding: 12px 15px;
  }

  .headingText {
    font-size: $fs-16;
    line-height: 40px;
    font-family: $font-medium;
  }

  .profileBlock {
    width: 100%;
  }

  .leftSpace {
    margin-left: 5px;
  }

  .logoBlock {
    .logoLink {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    @include tablet {
      margin-left: 40px;
    }

    @include mobileDevices {
      margin-left: 0;
    }
  }
  
  .logImg {
    float: left;
    margin-top: 4px;
  }

  .logoText {
    float: left;
    margin-top: 6px;
    font-size: 22px;
    margin-left: 30px;
    font-family: $font-bold;
    margin-bottom: 5px;

    @include mobileDevices {
      margin-left: 15px;
      font-size: 18px;
      margin-top: 12px;
    }
  }

  .searchText {
    float: left;
    margin-left: 40px;
    margin-top: 7px;

    @include mobileDevices {
      margin-top: 11px;
      margin-left: 0;
      width: 100%;
    }

    .slash {
        color: #C82C1F;
        margin-right: 8px;
        float: left;
        font-size: 20px;
        font-weight: 600;
        margin-top: 2px;

        @include mobileDevices {
          display: none;
        }
    }

    .searchLabel {
      font-size: 14px;
      float: left;
      margin-top: 5px;
    }
  }
  

  .menuList {
    cursor: pointer;

    &.signout {
      display: inline-block;
      margin-left: 10px;

      @include mobileDevices {
        float: right;
      }
    }

    &.signin {
      margin-top: 6px;
    }
  }

  .whiteBtn {
    display: block;
    padding: 6px 12px;
    background: transparent;
    color: $light;
    border-radius: 5px;
    margin-top: 3px;
    border: 1px solid $light;

    @include mobileDevices {
      margin-left: auto;
    }
  }

  .searchInputBlock {
    position: relative;
    display: inline-block;
    margin-left: 10px;

    .searchIcon {
      position: absolute;
      right: 6px;
      top: 7px;  
    }

    @include mobileDevices {
      margin: 10px 0;
      width: 100%;
    }
  }

  .spaceSlash {
    margin: 0 3px;
  }

  .searchInput {
    border: 1px solid $light;
    padding: 6px 10px;
    background-color: $dark;
    outline: none;
    border-radius: 4px;
    position: relative;


    &::placeholder {
      color: $light;
      font-size: 16px;
    }

    @include mobileDevices {
      width: 100%;
    }
  }

  .profilePic {
    margin-right: 10px;
    margin-top: 11px;
    float: left;
    max-width: 24px;
  }

  .profileText {
    float: left;
    margin-top: 2px;

    @include mobileDevices {
      width: calc(100% - 36px);

      .username {
        float: left;
        margin-top: 10px;
      }
    }
  }

  .userBlock {
    @include mobileDevices {
      width: calc(100% - 35px);
    }
  }

  .mobileHeaderUser {

    .menuBar {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    
    @include mobileDevices {
      display: flex;
      width: 100%;
      .menuBar {
        position: initial;
      }
    }
  }

  .menuBar {
    display: none;

    @include tablet {
      display: block;
      margin: 14px 10px 0;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.signinModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:first-child {
    margin-bottom: 20px;
  }

  button {
    width: 320px;
    font-size: 18px;
  }
}
