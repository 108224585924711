@import '../../../../commonStyles.scss';

.bannerBlock {
    background-color: $light;
    display: flex;

    .headingIcon {
      float: left;
      margin-right: 4px;
      margin-top: 8px;
    }

    .watch {
      cursor: pointer;
    }

    @include tablet {
      flex-wrap: wrap;
    }
  
    .subText {
      margin-bottom: 10px;
      font-size: 16px;
      color: $dark-100;
    }
  
    .contentBlock {
      width: 58%;
      padding: 72px 60px;
      position: relative;

      @include tablet  {
        width: 100%;
        order: 2;
        padding: 40px 30px;

      }
  
      .bannerImage {
          max-width: 300px;
          margin-bottom: 15px;

          @include mobileDevices {
            max-width: 100%;
          }
      }
  
      .headline {
          font-family: $font-bold;
          font-size: $fs-18;
          margin-bottom: 10px;

          // &.extraLarge {
          //   font-size: 52px;
          //   font-family: $font-bold;
          //   line-height: 1;
          // }

          // &.large {
          //   font-size: 50px;
          //   font-family: $font-bold;
          //   line-height: 1;
          // }
      }
    }

    .videoContainer {
      position: relative;
      width: 42%;

      @include tablet {
        width: 100%;
        height: 325px;
        order:1;
      }

      @include mobileDevices {
        height: 280px;
      }

      .imageBlock {
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        @include tablet {
          background-size: cover;
        }
      }

      .playImg {
        position: absolute;
        transform: translate(-50%, -50%);
        margin-left: 4px;
        left: 56%;
        top: 51%;
        width: 200px;
        img {
          float: left;
        }
        .watchText{
          font-family: $font-medium;
          font-size: 22px;
          color: $light;
          float: left;
        }
      }
    }
  }