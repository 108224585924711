@import '../../../../../commonStyles.scss';

.methodsBlock {
    &.topnone {
        margin-top: 0;
    }

    margin-top: 50px;
    .fileIcon {
        float: left;
        margin-right: 5px;
    }

    .overviewText {
        max-width: 100%;

        .editableTools {
          display: none;
        }

        &:hover {
          .editableTools {
            display: block;
          }
        }
    }

    .overviewText,
    .supportingDocs {
        font-family: $font-bold;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .methodImageBlock {
      position: relative;

      .editableTools {
        display: none;
      }

      &:hover {
        .editable {
          opacity: 0.5;
        }
        .editableTools {
          display: block;
          position: absolute;
          left: 40%;
          top: 50%;
          transform: translate(-40%,-50%);
          z-index: 9;
        }
      }
    }

    .overviewBlock {
        width: 88%;

        @include tablet {
            width: 100%;
        }

        .oviewview {
            line-height: 1.7;
            margin-bottom: 10px;
            white-space: pre-line;
            .editableTools {
              display: none;
            }
            &:hover {
                .editableTools {
                    display: block;
                }
            }
        }

        .methodImg {
            @include mobileDevices {
                max-width: 300px;
            }
        }
    }
}