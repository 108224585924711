@import "../../../../../../../commonStyles.scss";

.content-manager-products {
  padding: 40px 60px;

  @include mobileDevices {
    padding: 40px;
  }

  .marker {
    margin-left: 2px;
  }

  .headerblock {
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;

    .topic {
      font-family: $font-medium;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .heading {
    font-size: 40px;
    font-family: $font-bold;
    margin-bottom: 10px;
  }

  .buttonTools {
    margin-top: 30px;
    .buttonSpace {
      margin-right: 10px;
      padding: 8px 15px;
    }

    .editIcon {
      margin-right: 5px;
      float: left;
      margin-top: 2px;
    }
  }

    .viewBlock {
      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .note {
      text-align: right;
      margin: 8px 0;
    }

    .nameList {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        background-color: #f9f9f9;
        border: 1px solid #efefef;
        &:not(:first-child) {
            margin-left: -14px;
        }
    }

    .published {
      color: #757575;
    }

    .boldName {
      .name {
        font-family: $font-medium;
      }
    }

    .addIcon {
      float: left;
      margin-right: 5px;
      margin-top: 4px;
    }

    .addbtn {
      margin-top: 10px;
    }

    &.service-page,
    &.API-Page {
      .buttonTools {
        margin-top: 10px;
      }
    }
}

.label {
  font-family: $font-medium;
  margin-bottom: 5px;
  display: block;
}

.formControl {
  margin-top: 5px;
}

.noDataFound {
  text-align: center;
  font-size: 18px;
  font-family: $font-medium;
}

.ant-modal.editableContainer{
  max-width: 100%;
  width: 100%!important;
  top: 0 !important;
  vertical-align: top !important;
  padding-bottom: 0;
  .ant-modal-content {
    height: 100vh;

    .ant-modal-body {
      height: 100vh;
      max-height: 100vh;
    }
  }

  .ant-modal-body {
    padding: 0;
  }
  .mainContent {
    height: calc(100vh - 121px);
    overflow: auto;
  }
}

.editModal {
  .ant-select {
    display: block;
  }

  .controlItem {
    margin-top: 10px;
  }

  .note {
    font-size: $fs-12;
    margin-top: 10px;
    color: $neutral-300;
  }
}

.formItem {
  margin-bottom: 10px;
}

.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 365px;
  margin: 30px auto;
  text-align: center;
  .nodataHeading {
    font-size: 32px;
    color: $neutral-400;
    font-family: $font-medium;
  }

  .addBtn {
    margin: 10px auto;
    display: block;
  }
}

.customModalFooter {
  padding: 0 10px;
}

.imageUpload {
  border: 1px solid #ccc;
  width: 42%;
  margin-bottom: 10px;
}

.bannerImage {
  width: 100%;
}

.editableModalPopup {
  height: 95vh;
  .ant-modal-content {
    height:95vh;
  }
  .ant-modal-body {
    padding: 0;
    max-height: 95vh;
  }
  .ant-spin-dot {
    margin-left: -30px;
  }
}

.noteText {
  font-size: 12px;
  margin-top: 3px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  .btn {
    border: 1px solid #d9d9d9;
    background-color: $light;
    padding: 5px 18px;
    border-radius: 4px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  }

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}




