.acceptModal{
    .formControl{
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .label {
        font-family: "HelveticaNeueMedium", san-serif;
        margin-bottom: 5px;
        display: block;
    }
}