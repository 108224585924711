@import '../../../commonStyles.scss';

.ant-modal-body {
  padding: 15px;
  position: relative;
  max-height: 70vh;
  overflow: auto;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-modal .ant-modal-content {
  border-radius: 2px !important;
}

.leftTitle-popup {
  .ant-modal-title {
    text-align: left;
  }

  .ant-modal-body {
    padding: 30px 40px;
  }
}

.ant-modal-title {
  font-size: 24px !important;
  font-weight: 600;
  color: $dark !important;
  text-align: center;
}

.ant-modal {
  font-family: $font-regular !important;
}

.ant-modal-content {
  border-bottom: 6px;
  border-radius: 6px;
  padding: 0 !important;
}

.ant-modal-close {
  position: absolute !important;
  top: -32px !important;
  right: 0px !important;
}

.ant-modal-close-x {
  svg {
    color: $light;
  }
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-modal .ant-modal-close:hover {
  background-color: transparent;
}

.ant-modal-header {
  border-radius: 6px 6px 0 0;
  padding: 10px 15px !important;
  border-bottom: 1px solid #ccc;
}

.ant-modal-footer {
  border-radius: 0 0 6px 6px;
  padding: 15px 0;

  border-top: 1px solid #ccc;
}
