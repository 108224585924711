@import '../../../../../commonStyles.scss';

.productsCard {
    width: 100%;
    margin-top: 20px;
    background-color: $light;
    color: $dark-100;
    max-height: 330px;
    overflow: hidden;

    .productImg {
        @include mobileDevices {
            width: 100%;
        }
    }

    .listButton {
      margin-top: 10px;
      display: inline-block;
    }

    .productTitle {
        font-family: $font-bold;
        font-size: 24px;
        color: $primary;
    }

    .productContent {
        font-size: 16px;
        margin: 10px 0 25px;
    }

    & > div:first-child {
        float: left;
        width: 33%;
        max-width: 330px;
        overflow: hidden;
        @include mobileDevices {
            width: 100%;
        }
    }

    &> div:last-child {
        float: left;
        width: 67%;
        padding: 40px 60px;
        height: 280px;

        @include mobileDevices {
            width: 100%;
            height: auto;
        }
    }

    &::after {
        display: block;
        content: '';
        clear: both;
    }

    .servicesOffered {
        margin-top: 10px;
    }

    .serviceOfferedText {
        color: $dark-100;
    }

    .servicesOfferedRow {
        margin-top: 15px;

        div {
            font-family: $font-medium !important;
            font-size: $fs-16;
        }
    }
}

.stLine {
    margin: auto;
    height: 1px;
    width: 100%;
    background: #E2DEDE;
}

.productImg {
    width: 100%;
    min-height: 440px;
}