@import '../../../../commonStyles.scss';
.collapsible {
    padding: 10px 60px;
    background-color: $light;

    .heading {
        font-family: $font-bold;
        font-size: 24px;
    }

    @include mobileDevices {
        padding: 10px 30px;
    }
}