@import '../../../../commonStyles.scss';

.registration {
    padding: 60px 60px;

    @include mobileDevices {
        padding: 50px 30px;
    }

    .loginlink {
        display: inline-block;
        margin-left: 10px;

        a {
            color: $primary;
            margin-left: 5px;
        }
    }
    
    .topic {
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 5px;
    }

    .para {
        font-size: 16px;
        margin-bottom: 20px;
    }


    .stepperOuter {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: 1px dashed $primary-100;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        @include mobileDevices {
            width: 65px;
            height: 65px;
        }

        .stepperInner {
            width: 85px;
            height: 85px;
            border-radius: 50%;
            background-color: #CBDFDF;

            @include mobileDevices {
                width: 55px;
                height: 55px;
            }
        }
    }

    .stepperBlock {
        display: flex;
        flex-wrap: wrap;

        .stepperName{
            text-align: center;
            margin-top: 7px;
            margin-left: 5px;
        }

        & > div:not(:last-child) {
            margin-right: 60px;
            position: relative;

            &::after {
                content: '\2192';
                position: absolute;
                color: $primary-100;
                right: -54px;
                font-size: 51px;
                top: 10px;

                @include mobileDevices {
                    right: -51px;
                    font-size: 41px;
                    top: 0;
                }
            }
        }
    }

    .centerItem {
        display: flex;
        justify-content: center;

        @include tablet {
            margin-top: 20px;
        }
    }
}

.lineBlock {
    margin: 32px 40px;
    height: 1px;
    width: 90%;
    background: #E2DEDE;
}