@import '../../../../commonStyles.scss';

.navigation {
    border-right: 2px solid $neutral;
    background-color: $light;
    font-size: 16px;

    &.showMenu {
      height: calc(100vh - 67px);
      overflow: auto;
      min-height: calc(100vh - 67px);
      position: sticky;
      top: 67px;
    }


    .ant-tree-switcher {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      svg {
        display: none;
      }

      &:after {
        content: '';
        width: 10px;
        height: 10px;
        background-image: url('../../../../assets/images/down.png');
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 2px;
      }

      &.ant-tree-switcher_close {
        margin-top: 4px;
      }

      &.ant-tree-switcher_open {
        transform: rotate(180deg);
        margin-top: 1px;
      }
      
    }

   

    .main-nav-text {
      font-family: $font-bold;
      font-size: 14px;
      padding: 0 15px;
      margin-bottom: 5px;
    }

    .ant-tree .ant-tree-switcher-noop {
      display: none;
    }

    .subscribed .ant-tree-list-holder-inner {
      background-color: #eee;
    }

    .ant-tree {
      border-radius: none;
    }

    .treeView.ant-tree {
      .ant-tree-list-holder-inner {
        position: relative;
        .ant-tree-treenode {
          padding: 8px 15px;
          width: 100%;
          border-left: 4px solid transparent;
          .ant-tree-node-selected {
            background-color: transparent ;
          }
        }
      }
    }

    .ant-tree .ant-tree-node-content-wrapper:hover {
      background-color: transparent;
    }

    .activeTopic {
      .treeView.ant-tree {
        .ant-tree-list-holder-inner {
          .ant-tree-treenode {
            &.ant-tree-treenode-selected {
              border-left: 4px solid $primary;
              background-color: $primary-300;
            }
            .ant-tree-node-selected {
              background-color: transparent ;
            }
          }
        }
      }
    }

    .homeActive {
      .treeView.ant-tree {
        .ant-tree-list-holder-inner {
          .ant-tree-treenode {
              border-left: 4px solid $primary;
              background-color: $primary-300;
          }
        }
      }
    }



    .boldText {
        font-family: $font-medium;
        padding-left: 15px;
        font-size: 16px;
        margin: 10px 0;
    }

    .line {
        border: 2px solid $neutral;
        margin-bottom: 5px;
    }

    .mainNav {
      padding: 5px 0;
    }

    .arrowBtn {
        cursor: pointer;
    }

    .child-Menu {
        float: left;
        width: 100%;
        margin-left: 15px;
        .nested-item {
            padding: 10px;
            &:hover {
                color: $primary;
                cursor: pointer;
            }

            &.active {
                color: $primary;
            }
        }
        cursor: pointer;
    }
    .nav-icon {
        float: left;
        margin-right: 5px;
    }

    .otherMenu {
        .nav-item {
            padding-bottom: 10px;

            &:last-child {
              padding-bottom: 12px;
            }
        }
    }

    .greyBg {
        background-color: #F9F9F9;
    }

    .collapse-item {
        transform: rotate(180deg);
    }
}


