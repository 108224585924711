@import '../../../commonStyles.scss';

.ant-picker-cell-in-view {
  &.ant-picker-cell-today {
    .ant-picker-cell-inner {
      &::before {
        border: 1px solid $primary;
      }
    }
  }
}

.ant-picker-today-btn {
  color: $primary;
}

.ant-picker-focused {
  border: 1px solid #ccc;
  box-shadow: none;
}

.ant-picker-today-btn:active {
  color: $primary !important;
}

.ant-picker-today-btn:hover {
  color: $primary;
}

.ant-picker-header-view button {
  color: $primary;
}

.ant-picker-header-view button:active {
  color: $primary;
}

.ant-picker-header-view button:hover {
  color: $primary;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $primary;
}
