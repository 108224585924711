@import '../../../../../../../../commonStyles.scss';
.AddPartenerWrapper{
    display: grid;
    grid-template-columns: 48% 50%;
    column-gap: 15px;
    @include tabletPro{
        display: block;
    }
    .partnerNameText{
        width: 100%;
    }
    .formControl{
        margin-bottom: 10px;
        margin-top: 5px;
    }
}