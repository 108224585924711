@import '../../../../commonStyles.scss';

.codeBlock {
    padding: 0px;
    // padding: 40px 60px;
    &.white {
      background-color: $light;
    }

    .codingSection {
        background-color: $light;
    }

    @include tablet {
        padding: 40px 30px;
    }

    .titleText {
        font-family: $font-bold;
        font-size: 24px;
        line-height: 2.2;
    }



    .sectionHeader {
        border: 1px solid $neutral-100;
        margin-top: 20px;
        padding: 15px;

        .headerText {
            color: #757575;

            @include mobileDevices {
                font-size: 12px;
            }
        }

        .copyIcon {
            cursor: copy;
        }
    }

    .sectionBody {
        border: 1px solid $neutral-100;
        padding: 15px;
        border-top: none;
        line-height: 1.8;
        color: $dark-100;
        overflow: auto;


        .comment {
            color: #666;
        }

        .overview {
            margin-bottom: 10px;
            width: 70%;

            @include mobileDevices {
                width: 100%;
            }
        }

        .nestedCode {
            margin-left: 20px;
        }
    
        .codeValue {
            margin-left: 10px;
            display:inline-block;
            color: #D8764B;
        }
    
        .greentext {
            color: #367E7F;
        }
    }
}
