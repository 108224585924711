@import '../../../../commonStyles.scss';

.details {
    padding: 30px 60px;
    background-color: $light;

    @include mobileDevices {
        padding: 30px;
    }

    .details-header {
        font-size: 24px;
        margin-bottom: 10px;
        font-family: $font-medium;
    }

    .details-subHeading {
        font-size: 18px;
        font-family: $font-bold;
    }

    .details-body {
        font-size: $fs-16;
        line-height: 1.8;
        margin-bottom: 15px;
    }

    .details-card {
        padding: 20px;
        font-family: $font-bold;
        background-color: #F7F7F7;
    }
}