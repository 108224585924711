@import '../../../commonStyles.scss';

.buttonStyle {
    background-color: $primary;
    outline: none;
    border: none;
    padding: 8px 32px;
    text-align: center;
    font-size: $fs-14;
    color: $light;
    border-radius: 4px;
    font-family: $font-medium;
    border-bottom: 3px solid $primary-200;
    box-shadow: 0 3px 3px rgba(0,0,0,0.4);
    text-shadow: 0 2px 2px rgba(0,0,0,0.25);
    line-height: 26px;

    &:hover {
      background-color: $primary-400;
    }

}

.removeStlying {
    box-shadow: none;
    text-shadow: none;
    font-family: $font-regular;
    font-size: $fs-14;
    border: 1px solid $primary;
    padding: 10px;
}

.normalButton {
    box-shadow: none;
    text-shadow: none;
    padding: 8px 15px;
    font-family: $font-regular;
    font-size: $fs-14;
    border: none;
    line-height: 21px;
    border: 1px solid $primary;

    &.viewButton{
        padding: 6px 15px;
        margin-right: 10px;
        line-height: 1.5;
        width: auto;
    }

    &.mediumButton {
      font-size: 18px;
    }
}

.borderedBtn {
    margin-right: 0;
    width: max-content;

    .addIcon {
      float: left;
      margin: 5px 5px 0;
    }
}

.largeButton {
    padding: 8px 65px;
    margin-right: 0;
    font-size: 18px;
    text-decoration: none;
}

.mediumButton {
    text-decoration: none;
    padding:8px 25px;
    margin-right: 0;
    font-size: 1.28rem;
}

.buttonStyle {
    &.rounded {
        border-radius: 20px !important;
        padding: 5px 12px;
        box-shadow: none;
        text-shadow: none;
        cursor: auto;
    
        img {
            float: left;
            margin-top: 3px;
            margin-right: 5px;
        }
    }
}
.subscribed {
    background-color: $light;
    color: $dark;
    border: 1px solid $dark;
}
.unsubscribed {
    background-color: $dark-200;
    color: $light;
    border-bottom: none;
}
.Pending {
    border-bottom: none;
}
