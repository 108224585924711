@import '../../../../commonStyles.scss';

.specification {
  .heading {
    font-size: 24px;
    font-family: $font-medium;
  }

  .cardBlock {
    border-radius: 2px;
    padding: 10px;
    font-size: $fs-16;
    background-color: $light;
    margin-top: 10px;

    & > div {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $neutral;

      &:last-child {
        border-bottom: none;
        margin-bottom: none;
      }
    }

    .name {
      font-family: $font-medium;
      color: $dark;
      margin-bottom: 5px;
    }
  }
}
