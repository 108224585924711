.ExternalResponseAPI{
    .editModal{
        .formControl {
            margin-top: 5px;
            margin-bottom: 10px;
            &.switchPrognostics{
                display: flex;
                margin-right: 20px;
                .prognosticsLable{
                    margin-right: 25px;
                }
            }
            &.switchDaignostics{
                display: flex;
                margin-right: 10px;
                .daignosticsLable{
                    margin-right: 25px;
                }
            }
        }
        .switchControl{
            display: flex;
        }
        .tastCtrl {
            display: flex;
        }
        .formControl1 {
            margin-top: 5px;
            margin-bottom: 10px;
            padding: 10px 10px 5px 0px;
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .externalResponseWrapper{
            display: flex;
            margin-block: 10px;
            .labelView{
                width: 20%;
            }
            .splitView{
                width: 5%;
            }
            .valueView{
                width: 75%;
            }
        }
        .authinfoIcon{
            color: #888888;
            margin-left: 2px;
        }
    }
}
