@import '../../../commonStyles.scss';

.ant-breadcrumb {
  margin-bottom: 15px;
  .ant-breadcrumb-link {
    a {
      color: $dark-100;
      font-family: $font-regular;
      font-size: $fs-14;

      &:hover {
        background: none;
      }
    }
  }

  & li {
    // &::after {
    //   content: '/';
    //   margin: 0 5px;
    // }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}


.ant-breadcrumb li:last-child a:active {
  color: rgb(187, 184, 184) !important;
}
