@import '../../../commonStyles.scss';

.page-size-section {
  padding-left: 0;

  ul {
    order: 2;
    margin: 8px;
    padding: 0;
  }

  .page-size-block {
    order: 1;
    margin: 8px;
    margin-right: 10px;
  }
}

.page-count-block {
  margin-right: 20px;
}

.actionMenu {
  background-color: $primary;
  padding: 2px;
  text-align: center;
  color: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 6px;
  font-size: 21px;
  margin: 0 auto;
  cursor: pointer;
}

.refLinkItem {
  text-decoration: underline;
  color: #64a0f6;
  cursor: pointer;
  display: inline-block;
}

.ant-table {
  border: none;
  border-top: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .ant-table-thead {
    th {
      background-color: #FAFAFA !important;
      color: #757575 !important;
      font-size: $fs-14;
    }
  }

  .ant-table-tbody {
    tr:nth-child(even) {
      background-color: #fff;
    }

    td {
      word-break: break-word;
      font-size: $fs-14;
    }
  }

  .ant-table-thead th {
    border: none !important;
    border-bottom: 1px solid #f0f0f0 !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
  .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
    background: #f1f6fe;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #f1f6fe;
  }

  &.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
  }

  &.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: none;
  }
}
/* stylelint-disable no-descending-specificity */
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}

.ant-table-thead > tr > th {
  background-color: #f1f6fe;
  border: 1px solid #c5d8ec;
  border-left: 0;
  padding: 7px 10px;
  position: relative;
}

.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  background-color: #fff;
  padding: 15px;
  border: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-options .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: $primary;
}

.ant-pagination-item-active a {
  color: $primary;
}

.ant-table-tbody > tr > td {
  padding: 10px;
}

.ant-table-tbody tr {
  &:nth-child(odd) {
    background-color: #fff !important;
  }
}

.ant-table-cell {
  .ant-table-column-sorters {
    .ant-table-column-sorter {
      .ant-table-column-sorter-inner {
        .anticon-caret-up {
          display: none;

          svg {
            display: none;
          }

          &::after {
            content: url('../../../assets/images/UpSort.png');
            // top: 21px;
            // position: absolute;
            // right: 7px;
            visibility: visible;
          }

          &.active {
            display: inline-block;
          }
        }

        .anticon-caret-down {
          display: none;

          svg {
            display: none;
          }

          &::after {
            content: url('../../../assets/images/downSort.png');
            // top: 21px;
            // position: absolute;
            // right: 7px;
            visibility: visible;
          }

          &.active {
            display: inline-block;
          }
        }
      }
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #f1f6fe;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

.ant-table table {
  border-radius: 6px 6px 0 0;
}

.ant-table-content {
  // min-height: calc(100vh - 500px);
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

.ant-pagination-item-active:hover {
  border-color: $primary;
}

.ant-pagination-item-active:hover a {
  color: $primary;
}

.ant-pagination-item {
  min-width: 20px;
  border-radius: 0 !important;
  &.ant-pagination-item-active {
    border: none !important;
    color: $primary;
    border-bottom: 2px solid $primary !important;
  } 
}

.ant-pagination {
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border: none !important;
  }
}


.ant-pagination-item:hover {
  border-color: $primary;
}

.ant-pagination-item:hover a {
  color: $primary;
}

.ant-table-wrapper {
  border: 1px solid #E8EAEA;
  border-radius: 6px;
  .ant-table-container {
    .ant-dropdown-trigger {
      display: inline-block;
    }
  }
}

.ant-table-tbody {
  .disabled-row {
    opacity: 0.6;
    pointer-events: none;
  }
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $primary;
  border-color: $primary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: $primary;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: $primary;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-table-wrapper .ant-table-container,
:where(.css-dev-only-do-not-override-j0nf2s).ant-table-wrapper .ant-table {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-table-wrapper .ant-table { 
  color: #000 !important;
}