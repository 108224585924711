@import '../../../../commonStyles.scss';

.tileDescriptor {
    padding: 60px;
    border-top: 1px solid #e5e5e5;

    .buttonStyle  {
        @include tablet {
            &.subscriptionBtns {
                margin: 13px 0 18px;
            }
        }
    }

    .leftBlock {
        width: 100%;
        // width: 73%;

        @include tablet {
            width: 100%;
        }
    }

    .rightBlock {
        width: 27%;
        margin-top: 60px;
        @include tablet {
            width: 100%;
        }
    }

    .supportingDocs {
        font-family: $font-bold;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .tileView {
        position: relative;
        .editableTools {
            display: none;
        }
        &:hover {
            .editableTools {
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 9;
                width: 142px;
            }

            &.editable .subBlock {
                opacity: 0.5;
            }
        }
    }

    .subscribeBtn {
        padding: 9px 25px;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .arrowRight {
      transform: rotate(90deg);
      margin-left: 5px;
      margin-top: -2px;
    }

    button {
        &:first-child {
            margin-right: 10px;
        }
    }

    .LearnMore{
      font-size: 16px;
      color: #1C1E1E;
      text-decoration: none;
      font-family: $font-medium;
    }

    @include tablet {
        padding: 40px 30px;
    }

    &.white,
    .white {
        background-color: $light;
    }
    .disabled {
        background-color: #f8f8f8;
        cursor: not-allowed;
    }

    .topic {
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 5px;
        .editableTools {
            display: none;
        }
        &:hover {
            .editableTools {
                display: block;
            }
        }
    }
    

    .tileView {
        border: 1px solid rgba(0,0,0,0.19);
        border-radius: 2px;
        margin-right: 15px;
        width: calc(33% - 11px);
        margin-top: 45px;

        &:nth-child(-n+3) {
            margin-top: 0;
        }

        &:hover {
            .hoverStyle {
                color: $primary;
            }

            .title {
                color: $primary;
            }
        }

        .subBlock {
          padding: 25px;
        }

        .cardBannerImg {
            width: 100%;
            max-height: 136px;
        }
        
        .title {
            margin-top: 15px;
            font-size: 24px;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .subTitle {
            font-size: 16px;
            margin: 10px 0;
            color: $dark-100;
           

            .points {
                color: $dark-100;
            }

            &.fixedHeight  {
                max-height: 72px;
                min-height: 48px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        .link {
            color: $primary-100;
            text-decoration: none;
            font-size: $fs-16;
            font-weight: bold;
            margin-top: 20px;
            display: block;
            text-align: center;
        }
        

        @include tablet {
            width: calc(50% - 10px);
            margin-bottom: 20px;

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(3n) {
                margin-right: 20px;
            }
        }

        @include mobileDevices {
            width: 100%;
            margin-bottom: 20px;
            .subTitle {
                min-height: auto;
                height: auto;
            }

            &:nth-child(n) {
                margin-right: 0;
            }
        }
    }

    .tileView1 {
        border: 1px solid rgba(0,0,0,0.19);
        border-radius: 2px;
        margin-right: 15px;
        width: calc(33% - 11px);
        margin-top: 45px;

        &:nth-child(-n+3) {
            margin-top: 0;
        }

        .subBlock {
          padding: 25px;
        }

        .cardBannerImg {
            width: 100%;
            max-height: 136px;
        }
        
        .title {
            margin-top: 15px;
            font-size: 24px;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .subTitle {
            font-size: 16px;
            margin: 10px 0;
            color: $dark-100;
           

            .points {
                color: $dark-100;
            }

            &.fixedHeight  {
                max-height: 72px;
                min-height: 48px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        .link {
            color: $primary-100;
            text-decoration: none;
            font-size: $fs-16;
            font-weight: bold;
            margin-top: 20px;
            display: block;
            text-align: center;
        }
        

        @include tablet {
            width: calc(50% - 10px);
            margin-bottom: 20px;

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(3n) {
                margin-right: 20px;
            }
        }

        @include mobileDevices {
            width: 100%;
            margin-bottom: 20px;
            .subTitle {
                min-height: auto;
                height: auto;
            }

            &:nth-child(n) {
                margin-right: 0;
            }
        }
    }

    .servicesTile {
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        width: 80%;
    }

    .contentBlock {
        // width: 80%;
        width: 100%;
        white-space: pre-line;
        .editableTools {
            display: none;
        }

        &:hover {
            .editableTools {
                display: block;
            }
        }

        @include tablet {
            width: 100%;
        }
    }

    .formItem {
      margin-top: 10px;
    }
}

.addPoints {
    display: block;
}

.listedPoints {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  li {
    display: block;
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    margin-bottom: 10px;
    padding: 5px;

    .trashImg {
      color: #666;
      cursor: pointer;
      margin-right: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}

.viewButton.addBtn {
    line-height: 1.2;
}

.addPoints {
  width: 85%;
}

