@import '../../../../commonStyles.scss';
.ExternalResponseDescriptor {
    padding: 40px 60px;
  
    .whiteBadge {
      background-color: $light;
      padding: 10px 15px;
      margin-bottom: 10px;
      font-family: $font-medium;
      font-size: $fs-16;
    }
  
    .heading {
      font-family: $font-medium;
      margin-bottom: 20px;
      font-size: 24px;
    }
    
    .normalButton {
        box-shadow: none;
        text-shadow: none;
        padding: 8px 15px;
        font-family: "HelveticaNeue", san-serif;
        font-size: 1rem;
        border: none;
        line-height: 21px;
        border: 1px solid $primary;
        &.viewButton {
            margin-right: 10px;
            width: auto;
        }
    }
    .externalResptitle{
      float: left;
    }
    .externalRespAction{
      float: right;
      @include mobileDevices{
        float: unset;
        text-align: center;
        margin-bottom: 10px;
      }
      .normalButton[disabled] {
        opacity: 0.5;
        pointer-events: none;
      } 
      .viewButton[disabled] {
        opacity: 0.5;
        pointer-events: none;
      } 
    }
    .outlineButton{
        border: 1px solid #398284;
        padding: 9px;
        background-color: #fff;
        color: #367E7F;
        font-family: "HelveticaNeueMedium", san-serif;
        outline: none;
        cursor: pointer;
        box-shadow: none;
        border-radius: 4px;
        text-shadow:none;
        &:hover {
            background-color: #444;
            color: #fff !important;
            border: 1px solid #444;
        }
        &.viewButton {
            margin-right: 10px;
            width: auto;
        }
    }

    .RegisterModal{
      .ant-modal-body {
        padding: 15px 40px;
      }
  }

  
}
 