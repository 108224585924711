@import '../../commonStyles.scss';

*,::after,::before {
 box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
}
body {
  margin: 0;
  font-family: "HelveticaNeue",sans-serif !important;
  background-color: #Efefef;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  overflow-x: hidden;

  p {
    margin: 0;
  }
}
.spinnerBlock {
  .ant-spin {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
}
.clearfix {
  &::after {
    clear: both;
    display: block;
    content: '';
  }
}

.float-left {
  float:left;
}

.float-right {
  float: right;
}

.splitRow {
  display: flex;

  .splitCol {
    &:first-child {
      width: 19.5%;

      @include tablet {
        position: absolute;
        width: 100%;
        z-index: 99;
        height: 100%;
      }
    }

    &:last-child {
      width: 80.5%;

      @include tablet {
        width: 100%;
      }
    }
  }
}

.page404 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

:where(.css-dev-only-do-not-override-1n7nwfa)[class^="ant-col"], :where(.css-dev-only-do-not-override-1n7nwfa)[class*=" ant-col"] {
  font-family: "Helvetica",sans-serif !important;
}