@font-face {
  font-family: 'HelveticaNeue';
  src: url("./assets/fonts/HelveticaNeueRoman.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueBold';
  src: url("./assets/fonts/HelveticaNeueBold.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url("./assets/fonts/HelveticaNeueMedium.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLight';
  src: url("./assets/fonts/HelveticaNeueLight.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}