@import '../../../commonStyles.scss';

:where(.css-dev-only-do-not-override-j0nf2s).ant-btn-default:not(:disabled):hover {
  color: $dark;
  border-color: $neutral-600;
}

.ant-upload-wrapper {
  .anticon svg {
    float: left;
  }

  .ant-upload-list-item {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 15px 10px;
  }

  .ant-upload-list-item-action {
    opacity: 1 !important;
  }
}


.ant-btn-default:not(:disabled):hover {
  color: #000;
  border-color: #ccc;
}

