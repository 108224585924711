.ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-button:not(.ant-btn-primary):hover {
  color: #000 !important;
  border-color: #d9d9d9 !important;
}

.ant-input-search-button:not(.ant-btn-primary) {
  svg {
    margin-top: -8px;
  }
}

.ant-input{
  border-radius: 4px !important;
  &:hover,
  &:focus {
    border-color: #aaa !important;
    box-shadow: none !important;
  }
}

.searchbar {
  width: 245px;

  .ant-input-affix-wrapper {
    padding: 7px;
  }
  
  .ant-input {
    padding: 6px 8px;
    border: 1px solid #BBBFBF;
    border-right: none;
  }

  .ant-input-group-addon {
    border: 1px solid #d9d9d9 !important;
    border-left: none !important;
    button {
      border: none !important;
      height: 36px;
      box-shadow: none;
    }
  }

  .ant-input-affix-wrapper:focus, 
  .ant-input-affix-wrapper-focused {
    border: 1px solid #ccc !important;
    box-shadow: none;
    border-right: none !important
  }
  
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ccc;
    border-right: none;
  }
}