@import '../../../../commonStyles.scss';
.products {
        padding: 60px;

        @include mobileDevices {
            padding: 60px 30px;
        }
        .topic {
            font-family: $font-bold;
            font-size: 32px;
            margin-bottom: 5px;

            .editableTools {
                display: none;
            }

            &:hover {
                .editableTools {
                    display: block;
                }
            }
        }
        .productContentBlock {
            width: 56%;
            margin-bottom: 60px;
            color: $dark-100;
            @include tablet {
                width: 100%;
            }

            
            .editableTools {
                display: none;
            }

            &:hover {
                .editableTools {
                    display: block;
                }
            }
        }
    
}