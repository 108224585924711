@import '../../../commonStyles.scss';

.customAccordion {
  margin-top: 8px;

  .accordionHeading {
    border: 0 !important;
    border-radius: 6px;

    .accordion__button {
      background-color: #f2f6fa !important;
      color: $primary !important;
      font-size: 1rem !important;
      font-family: $font-medium;
      border-radius: 6px;
      padding: 10px 8px !important;
      position: relative !important;
      border: 1px solid #ccdbea;

      &[aria-expanded='true'] {
        background-color: $primary !important;
        color: #fff !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid $neutral;
      }
    }
  }

  .accordion__button::before {
    display: inline-block;
    content: "";
    height: 8px;
    width: 8px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    position: absolute;
    right: 5px;
    top: 13px;
  }

  .accordionPanel {
    font-size: 1rem !important;
    background-color: #fff;
    border: 1px solid #e2e6e9;
    padding: 5px;
  }

  .ant-collapse-content-box {
    font-size: 16px !important;
  }

  .ant-collapse {
    border: 0;

    .ant-collapse-item {
      background-color: $light;
      border: 0;
      border-bottom: 1px solid #eaeaea;

      &:last-child {
        border-bottom: none;
      }

      .ant-collapse-header {
        border-radius: 0;
        position: relative;
        padding: 12px 17px;

        .ant-collapse-header-text {
          font-size: $fs-14;
          font-family: $font-bold;
          font-size: $fs-18;
        }

        .ant-collapse-expand-icon {
          position: absolute;
          right: 25px;
          top: 20px;

          .ant-collapse-arrow {
            svg {
              display: none;
            }

            &::after {
              content: url('../../../assets/images/add.png');
              position: absolute;
              top: -4px;
              left: -6px;
              visibility: visible;
            }
          }
        }
      }

      &.ant-collapse-item-active {
        background-color: $light;
        color: #fff;

        .ant-collapse-header {
          border-radius: 0;

          .ant-collapse-expand-icon {
            position: absolute;
            right: 25px;
            transform: rotate(180deg);
            top: 10px;

            .ant-collapse-arrow {
              svg {
                display: none;
              }

              &::after {
                content: url('../../../assets/images/remove.png');
                position: absolute;
                top: 0;
                left: 0;
                visibility: visible;
              }
            }
          }
        }
      }

      .ant-collapse-content {
        border-top: 0;
      }
    }
  }
}

//   .anticon {
//     &.anticon-right {
//       &.ant-collapse-arrow {
//         svg {
//           display: none;
//         }

//         &::after {
//           content: url('../../../assets/images/accordown.png');
//           position: absolute;
//           top: 0;
//           left: 0;
//           visibility: visible;
//         }
//       }
//     }
//   }
// }
