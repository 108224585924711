@import '../../../../../commonStyles.scss';

.failedinfo{
    .borderBlock{
        border: 1px solid #D2D5D5;
        padding: 10px;
        border-radius: 4px;
        display: flex;
        .iconView{
            display: flex;
        }
        .textView{
            display: inline-grid;
            margin-left: 20px;
            .title{
                color: #000;
                font-family: $font-bold;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 10px;
                height: auto;
            }
            .description{
                color: #000;
                font-family: $font-regular;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10px;
                margin-top: 8px;
                height: auto;
            }
        }
    }
}