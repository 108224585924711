.ApproveView{
    .formControl {
        margin-top: 0;
    }
    .ApprovalWrapper{
        display: flex;
        .labelView{
            width: 20%;
        }
        .splitView{
            width: 5%;
        }
        .valueView{
            width: 75%;
        }
    }
}