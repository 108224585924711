.simulateExternalResp{
    .formControl {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    
    .simulateWrapper{
        display: flex;
        .labelView{
            width: 30%;
        }
        .splitView{
            width: 5%;
        }
        .valueView{
            width: 45%;
        }
    }
    
}
