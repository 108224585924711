@import '../../../commonStyles.scss';

.ant-input[disabled] {
  color: #858585;
  font-weight: 500;
}

.ant-input {
  font-weight: 500;
}
