
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable block-opening-brace-space-before */
/* stylelint-disable block-closing-brace-empty-line-before */
/* stylelint-disable no-missing-end-of-source-newline */
/* stylelint-disable no-eol-whitespace */
/* stylelint-disable color-hex-case */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable indentation */
@import '../../../commonStyles.scss';
.ant-checkbox-checked {
  &:not(.ant-checkbox-disabled) {
    &:hover .ant-checkbox-inner {
      background-color: $primary;
      border-color: transparent;
    }
  }
}
.defaultCheckBox {

    &.dottedCheckBox {
        &:hover {
            .ms-Checkbox-checkmark {
              opacity: 0;
            }
        }
        .ms-Checkbox-checkbox {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-top: 2px;
            background-color: $light;
            border: 1px solid $primary;
        }
        &.is-checked  {
            .ms-Checkbox-checkbox{
                background-color: $light;
                border: 1px solid $primary;

                &::after {
                    content: "";
                    background-color: $primary;
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    border-radius: 2px;
                }
                .ms-Checkbox-checkmark {
                    display: none;
                }
            }
        }
        .ms-Checkbox-text {
            color: #222;
        }
    }

}

.dottedCheckBox {
    .ant-checkbox-inner {
      border-radius: 5px;
      border: 1px solid $primary;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $light;
        border-color: $primary;
        border-radius: 5px;
  
        &::after {
          height: 10px;
          width: 10px;
          background-color: $primary;
          border-radius: 3px;
          top: 2px;
          left: 2px;
          transform: none;
          border: 0;
        }
      }
    }
  }

  .ant-checkbox-wrapper {
  font-size: $fs-14;
  }

  .ant-checkbox-checked::after{
    border-color: $primary;
    border-radius: 6px;
    border: 0;
  }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: $primary !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: $primary;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $primary;
}