@import '../../../commonStyles.scss';

.ant-tabs-tab {
  font-weight: $font-medium;

  &:last-child {
    border-top-right-radius: 6px !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $light !important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-tabs .ant-tabs-tab:hover {
  color: inherit !important;
}
.ant-tabs-tab:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-tabs .ant-tabs-tab-btn:active {
  color: rgba(0, 0, 0, 0.85)!important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-bottom: 0;
}

.ant-tabs-tabpane {
  padding: 10px;
  border-radius: 6px;
  border-top-left-radius: 0;
}

.ant-tabs-card.ant-tabs-top .ant-tabs-nav .ant-tabs-tab {
  background-color: $light;

  &:first-child {
    border-radius: 0;
  }

  &:nth-last-child(2) {
    border-radius: 0 !important;
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
}

.ant-tabs > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: $primary;
  color: $light;
}

.ant-tabs-content-holder {
  border: 1px solid #ddd;
  border-top: 1px solid $primary;
  border-radius: 4px;
  border-top-left-radius: 0;
}

.ant-tabs-content {
  background-color: #fff;
  border-radius: 4px;
  border-top-left-radius: 0;
}

.tabActiveImg {
  display: none;
}

.tabImg {
  display: block;
  margin: 0 auto 4px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  .tabImg {
    display: none;
  }

  .tabActiveImg {
    display: block;
    margin: 0 auto 4px;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 !important;
}

.secondaryTab {
  &.ant-tabs-card.ant-tabs-top .ant-tabs-nav .ant-tabs-tab {
    border-bottom: 2px solid transparent !important;
    &.ant-tabs-tab-active {
      background-color: transparent !important;
      border-bottom: 2px solid $primary !important;

      .ant-tabs-tab-btn {
        color: $primary !important;
        font-family: $font-medium;
      }
    }
  }

  .ant-tabs-content-holder {
    border-top: none !important;
  }
}
