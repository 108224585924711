@import '../../../../commonStyles.scss';

.Description {
  padding: 60px;

  .heading {
    font-size: 24px;
    font-family: $font-medium;
  }

  .listView {
    background-color: $light;
    font-family: $font-medium;
    margin-top: 10px;
    padding: 20px;
  }

  .borderedList{
    border: 1px solid $neutral-700;
    margin-bottom: 0;
    padding: 10px;
    padding-left: 30px;

    li {
      margin-bottom: 5px;
    }
  }

}