@import '../../../commonStyles.scss';

.ant-radio {
  .ant-radio-inner {
    border: 1px solid $primary;

    &::after {
      background-color: $primary;
    }
  }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primary;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $primary;
  background-color: $light;
}