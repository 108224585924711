@import '../../../commonStyles.scss';

.swaggerBlock {
  margin: 60px;

  .swaggerContainer {
    padding: 20px 10px 10px;
    background-color: $light;
  }

  .wrapper {
    &:last-child {
        padding-bottom: 5px;
    }
  }

  .swagger-ui .info {
    margin-top: 0;
  }

  .swagger-ui .scheme-container {
    box-shadow: 0 0px 2px 0 rgba(0,0,0,.15);
  }
}
