@import '../../../../../commonStyles.scss';

.subscription-modal {
   color: $dark;
  .heading {
    font-size: 18px;
    font-family: $font-medium;
    margin-bottom: 20px;
  }

  .title {
    font-size: 16px;
    margin: 10px 0;
  }

  .line {
    border-top: 1px solid #EFEFEF;
    margin: 20px 0;
  }

  .bgGrey {
    background-color: $neutral-200;
    padding: 10px;
    color: #727777;
  }

  .checkboxList {
    margin-top: 10px;
  }

  .centerButton {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .ant-modal-body {
    height: 80vh;
    overflow: auto;
  }

  .ant-checkbox-wrapper {
    display: block;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;

    span {
        float: left;
        width: 20px;

        &:last-child {
          width: 96%;
        }
    }
  }
}
