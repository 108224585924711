$dark: #000;
$dark-100: #3C4043;
$dark-200: #393C3C;
$light: #fff;
$red: #C82C1F;
$neutral: #EFEFEF;
$neutral-100: #BDBDBD;
$neutral-200: #FAFAFA;
$neutral-300: #757575;
$neutral-400: #555959;
$neutral-500: #BBBFBF;
$neutral-600: #aaa;
$neutral-700: #D2D5D5;
$primary: #367E7F;
$primary-100: #398284;
$primary-200: #285E5F;
$primary-300: #EAF5F6;
$primary-400: #2E686A;
$green: #04844B;
$font-regular: 'HelveticaNeue',san-serif;
$font-bold: 'HelveticaNeueBold',san-serif;
$font-light: 'HelveticaNeueLight',san-serif;
$font-medium: 'HelveticaNeueMedium',san-serif;

$fs-12: 0.857rem; 
$fs-14: 1rem;
$fs-16:1.14rem;
$fs-18: 1.28rem;
$fs-32: 2.2848rem;
@mixin LargeDesktop1900 {
    @media only screen and (min-width: 1900px) {
      @content;
    }
}
  
  @mixin Desktop1536 {
    @media only screen and (max-width: 1536px) {
      @content;
    }
  }
  
  @mixin Desktop1280 {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
  
  @mixin Desktop1200 {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
  
  @mixin LargeDesktop1500 {
    @media only screen and (min-width: 1500px) {
      @content;
    }
  }
  
  @mixin Desktop1100 {
    @media only screen and (max-width: 1100px) {
      @content;
    }
  }
  
  @mixin smallDesktop {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  
  @mixin Device1000 {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }
  
  @mixin tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }

  @mixin tabletPro {
    @media only screen and (max-width: 820px) {
      @content;
    }
  }
  
  @mixin mobileDevices {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }
  
  @mixin Desktop1800 {
    @media only screen and (max-width: 1860px) {
      @content;
    }
  }