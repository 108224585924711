@import '../../../../commonStyles.scss';

.methodTabs {
  padding: 0px 60px 40px 60px;

  &.white {
    background-color: $light;
  }
}

.ant-tabs-card.ant-tabs-top .ant-tabs-nav .ant-tabs-tab {
    background-color: transparent;
    border: none !important;
}

.ant-tabs-tab-active {
    background-color: #367E7F !important;
    border: none !important;
}

