@import '../../../../commonStyles.scss';

.footerContent {
    padding: 20px 60px;
    margin-bottom: 60px;

    @include mobileDevices {
        padding: 20px 30px;
    }

    .nodecoration {
        text-decoration: none;
    }

    .headingLabel {
      .editableTools {
        display: none;
      }

      &:hover {
        .editableTools {
          display: block;
        }
      }
    }

    .subcribeLink {
        a {
          color: $primary;
          font-size: $fs-16;
          font-family: $font-medium;
          display: block;
          margin-top: 10px;
        }

        &.nodecoration {
            text-decoration: none;
        }

        .editableTools {
          display: none;
        }
    
        &:hover {
            .editableTools {
                display: block;
            }
        }
    }
    
    .boldText {
        font-size: 24px;
        margin-bottom: 15px;
        font-family: $font-medium;
    }

    .contentbox {
        font-size: $fs-16;
        .editableTools {
            display: none;
          }
    
        &:hover {
          .editableTools {
            display: block;
          }
        }
    }

    .label {
        font-size: 32px;
        font-family: $font-bold;
    }

    .stLine {
        margin-top: 15px;
    }

    .supports {
        margin-top: 50px;
    }
}