@import '../../../commonStyles.scss';

.treeView {
    .ant-tree-title {
        font-size: 16px;
    }

    &.ant-tree .ant-tree-treenode {
        padding: 8px 0;
    }
}