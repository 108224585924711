@import '../../../../commonStyles.scss';

.projectDoc {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-bottom: none;
    }

    .boxList {
        width: 32px;
        height: 32px;
        line-height: 30px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
    }

    &.viewable {
      .titleDoc {
        margin-left: 22px !important;
        width: 100%;
        display: block;
        color: $primary;
        font-family: $font-regular;
      }

      .leftDoc {
        width: 100%;
      }
    }


}
  

.spec {
    .spec-heading {
        font-family: $font-medium;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .ant-upload-list {
        display: none;
    }

    .text {
        font-size: 16px;
    }

    .title {
        font-family: $font-medium;
    }
}

.card {
    padding: 15px 25px;
    border-radius: 4px;
    border: none;

    .borderedBtn {
      margin-top: 5px;
    }

    .addIcon {
      margin-left: 5px;
      margin-right: 5px;
    }

    .leftDoc {
      width: 70%;
      margin-top: 8px;

  
      .titleDoc {
        margin-left: 5px;
      }
    }

    .fileListIcon {
      float: left;
      margin-top: 3px;
    }

    .toolbox {
      display: flex;
    }

    .downloadbox {
      border: 1px solid #BBBFBF;
      margin-right: 10px;
    }

    .trashbox {
      background-color: #FBEAE8;
    }
  }