@import '../../../commonStyles.scss';

.outlineButton {
    border: 1px solid $primary-100;
    padding: 9px;
    background-color: $light;
    color: $primary;
    font-family: $font-medium;
    outline: none;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #444 !important;
      color: $light !important;
      border: 1px solid #444;
    }

    &.normalButton {
        border: 1px solid transparent;
        color: $dark;
        padding: 8px 15px;
        font-family: $font-regular;
    }

    &.viewButton {
      border: 1px solid #ccc;
      padding: 6px 15px;
      line-height: 1.5;
    }

    &.borderedButton{
      margin-top: 10px;
      width: 100%;
      border: 1px solid #ccc;
      box-shadow: 0 1px 2px #ccc;
    }

    &.undo {
      background: transparent;
      color: #fff;
    }
}

.outlineButton-disabled {
  border: 1px solid $primary-100;
  padding: 9px;
  background-color: $light;
  color: $primary;
  font-family: $font-medium;
  outline: none;
  cursor: not-allowed;
  border-radius: 4px;

  // &:hover {
  //   background-color: #444;
  //   color: $light !important;
  //   border: 1px solid #444;
  // }

  &.normalButton {
      border: 1px solid transparent;
      color: $dark;
      padding: 8px 15px;
      font-family: $font-regular;
  }

  &.viewButton {
    border: 1px solid #ccc;
    padding: 6px 15px;
    line-height: 1.5;
  }

  &.borderedButton{
    margin-top: 10px;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 1px 2px #ccc;
  }

  &.undo {
    background: transparent;
    color: #fff;
  }
}
