@import '../../../../../../../../commonStyles.scss';

.patnersView {
    .infoBorder {
        border: 1px dashed #9747FF;
        width: 40%;
        padding: 10px;
        border-radius: 5px;
        margin-top: 5px;
    }

    .headerblock {
        border-bottom: none;
        margin-bottom: 10px;
    }

    .tiles {
        margin-top: 10px;
        .ant-card {
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
            margin-top: 15px;
            border-radius: 0;
        }
    }

    .supportBlock {
        margin-bottom: 5px;
    }

    .labelText {
        margin-bottom: 0;
    }

    .ant-card .ant-card-body {
        padding:20px;
    }

    .ant-card .ant-card-head {
        padding: 20px;
    }
}

.statusIcon {
  float: left;
}

.collapseItem {
    transform: rotate(180deg);
}

.outlineButton.activateButton {
  background-color: #E6F3ED;
  border: 1px solid #036A3C;
}

.leftMoreSpace {
    margin-left: 2px;
    cursor: pointer;
    display: inline-block;
    margin-top: 5px;
}

.partnersTable {
    .subHeading {
        margin: 10px;
    }

    .expandIcon {
        margin-top: 15px;
        cursor: pointer;
    }
}

.outlineButton.deactivateButton {
    background: #FBEAE8;
    color: #ae2116;
    border: 1px solid #ae2116;
  }

.titleText {
    font-size: 32px;
    font-family: $font-bold;
}

.borderLine {
  border: 1px solid #E5E5E5;
  margin: 20px 0;
}
