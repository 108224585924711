@import '../../../../commonStyles.scss';

.splitLine {
    height: 1px;
    width: 100%;
    background-color: $neutral;
}

.availableMethods {
    padding: 40px 60px;
    &.white {
      background: $light;
    }

    @include tablet {
        padding: 40px 30px;
    }

    .methods {
        display: flex;
        margin-bottom: 10px;

         & > div {
            cursor: pointer;
            &.active {
                color: $primary-100;
                position: relative;
                &::before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid #367E7F;
                    position: absolute;
                    top: 31px;
                    left: 22px;
                }
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &::after {
                content: '/';
                margin: 0 8px;
            }
         }
    }

    .caption {
        font-size: 32px;
        font-family: $font-bold;
        margin-bottom: 10px;
    }

    .title {
        margin-bottom: 10px;
    }

    .split {
        height: 1px;
        width: 100%;
        background-color: $neutral-100;
        margin-bottom: 10px;

        &.splitTop {
            margin-bottom: 0;
            margin-top: 20px;
        }
    }
}