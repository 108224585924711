@import '../../../../../commonStyles.scss';

.footer {
    background-color: $dark;
    color: $light;
    padding: 10px;

    .footer-link {
        margin-top: 12px;
        a {
            margin-right: 20px;
            text-decoration: none;
            color: $light;

            @include mobileDevices {
                display: block;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }

    @include mobileDevices {
        img {
            text-align: center;
            display: block;
            margin: 0 auto;
        }
        .footerLeft {
            width: 100%;
        }

        .footerRight {
            width: 100%;
            text-align: center;
        }
    }
}
