@import '../../../../../commonStyles.scss';

.rolesAndPermissions {
  padding: 40px;

  @include mobileDevices {
    padding: 40px;
  }

  .patnersView {
    margin-bottom: 10px;
  }
  .buttonSpace {
    margin-right: 10px;
  }

  .rolesSave {
    margin-right: 0;
  }
  .itemSelect {
    width: 269px;
  }
  .imgElement {
    margin-right: 5px;
  }

  .resourcesData {
    img {
      margin-right: 5px;
    }
  }

  .resourcesimg {
    margin-right: 5px;
  }

  .buttonTools {
    margin: 10px 0;

    .outlineButton {
      margin-bottom: 15px;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
  .boldName {
    .name {
      font-family: $font-medium;
    }
  }
  .heading {
    font-size: 40px;
    font-family: $font-bold;
    margin-bottom: 10px;
  }

  .cardBlockContainer {
    padding: 10px;
    background-color: $light;
    margin-bottom: 20px;
  }

  .toggleButton {
    display: inline-block;
    margin-right: 15px;
  }

  .borderedContainer {
    border: 1px solid #D2D5D5;
    padding: 10px;
    font-size: 16px;
    font-family: $font-medium;
  }

  .productOption {
    margin-bottom: 15px;
    width: 100%;
  }

  .leftSpace {
    margin-left: 5px;
  }

  .editIcon {
    margin-right: 5px;
    float: left;
    margin-top: 1px;
  }

  .headerblock {
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
  }

  .subHeading {
    margin-bottom: 10px;
    font-size: $fs-18;
    font-family: $font-medium;
  }

  .description-text {
    margin-bottom: 25px;
  }

  .cardList {
    margin-bottom: 10px;

    div {
      margin-bottom: 10px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 10px;
    }

    &:last-child {
      div:last-child {
        border-bottom: none;
      }
    }
  }

  .account,
  .mail {
    margin-right: 5px;
    max-width: 20px;
  }

  .searchbar {
    width: 447px;
    margin-bottom: 20px;

    @include mobileDevices {
      width: 100%;
    }
  }

  .innerTabs {
    margin-bottom: 15px;

    .tabList {
      width: calc(19% - 3px);
      margin-right: 15px;
      display: inline-block;
      background-color: $light;
      cursor: pointer;
      border-radius: 2px;
      line-height: 28px;

      &.active {
        box-shadow: 2px 2px 2px #ccc;
      }

      @include mobileDevices {
        width: 100%;
        margin-bottom: 15px;
      }

      &:last-child {
        margin-right: 0;
      }

      .colorBlock {
        height: 6px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      .boxshadow {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
      }

      &.Pending {
        .colorBlock {
          background-color: #F39200;
        }

        .boxshadow {
          background: rgba(242,145,0,0.4);
        }
      }
      
      &.Rejected {
        .colorBlock {
          background-color: #AE2116;
        }

        .boxshadow {
          background: rgba(174,33,22,0.6);
        }
      }

      &.InProgress {
        .colorBlock {
          background-color: #008000;
        }
        .boxshadow {
          background: rgba(0,128,0,0.4);
        }
      }

      &.ReadyforProduction {
        .colorBlock {
          background-color: #757575;
        }
        .boxshadow {
          background: rgba(117,117,117,0.4);
        }
      }

      &.FullProduction {
        .colorBlock {
          background-color: #1572C2;
        }
        .boxshadow {
          background: rgba(21,114,194,0.4);
        }
      }

      .listBlock {
        padding: 5px 8px;
        font-size: 12px;
        font-family: $font-bold;
      }
    }   
  }

  .projectDoc {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-bottom: none;
    }
  }

  .card {
    padding: 15px 25px;
    border-radius: 4px;
    border: none;

    .borderedBtn {
      margin-top: 5px;
    }

    .addIcon {
      margin-left: 5px;
    }

    .leftDoc {
      width: 70%;
      margin-top: 8px;

  
      .titleDoc {
        margin-left: 5px;
      }
    }

    .fileListIcon {
      float: left;
      margin-top: 3px;
    }

    .toolbox {
      display: flex;
    }

    .downloadbox {
      border: 1px solid #BBBFBF;
      margin-right: 10px;
    }

    .trashbox {
      background-color: #FBEAE8;
    }
  }

  .resources {
    color: #398284;

    .resources-block{
      margin-bottom: 10px;
    }
  }

  .link {
    color: #398284;
  }

  .support {
    margin-top: 10px;
  }

  .labelText {
    font-family: $font-medium;
    color: $dark;
    margin-bottom: 8px;
  }

  .dateBlock {
    display: flex;
    margin: 15px 0;

    > div {
      width: 50%;
    }
  }

  .spec {
    .spec-heading {
      font-family: $font-medium;
      margin-bottom: 10px;
      font-size: 16px;
    }

    .text {
      font-size: 16px;
    }

    .title {
      font-family: $font-medium;
    }
  }

  .textBlock {
    margin-bottom: 10px;

    p {
      &:first-child {
        color: #727777;
      }
    }
  }

  .ant-collapse-header-text {
    font-size: 20px;
    color: #757575;
    font-family: $font-regular !important;
  }

  .tickIcon {
    margin-right: 15px;
    float: left;
  }

  .cardContainer {
    background-color: $dark;
  }

  .cardListBlock {
    background-color: $light;

    .heading {
      font-family: $font-bold;
      font-size: 18px;
    }

    .status {
      font-family: $font-medium;
      margin-right: 10px;
    }
  }

  .cardHeader{
    padding: 18px;
    border-bottom: 1px solid #e5e5e5;
  }

  .accordionView {
    padding: 25px;

    .customAccordion {
      border: 1px solid #E8EAEA;
      margin-top: 0;
    }
  }

  .roundButton {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: $fs-18;
    background-color: $primary;
    display: inline-block;
    margin-right: 10px;
    color: $light;
    border-radius: 50%;
  }
}
