@import '../../../commonStyles.scss';

.ant-select-selection-item {
  border-color: $neutral-500;
}

.ant-select-single {
  .ant-select-selector {
    border-radius: 4px;
  }
  .ant-select-arrow {
    .anticon {
      &.anticon-down {
        &::after {
          content: url('../../../assets/images/Dropdown.png');
          top: -1px;
          position: absolute;
          visibility: visible;
          right: -4px;
        }

        svg {
          display: none;
        }
      }

      &.anticon-search {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &.ant-select-show-arrow {
    .ant-select-selection-placeholder {
      color: $neutral-100;
    }
  }
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #ccc;
  box-shadow: none;
  outline: 0;
}

.ant-select-multiple {
  .ant-select-arrow {
    .anticon {
      &.anticon-down {
        &::after {
          content: url('../../../assets/images/Dropdown.png');
          top: -1px;
          position: absolute;
          visibility: visible;
          right: -4px;
        }

        svg {
          display: none;
        }
      }

      &.anticon-search {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &.ant-select-show-arrow {
    .ant-select-selection-placeholder {
      color: $neutral-100;
    }
  }
}

.ant-input:focus,
.ant-input-focused {
  border-color: $neutral-100;
  outline: 0;
  box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
  border-color: $neutral-100 !important;
  box-shadow: none !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-j0nf2s).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: $neutral-100 !important;
  box-shadow: none !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $neutral-100;
  box-shadow: none;
}
