@import '../../../commonStyles.scss';

.ant-spin-dot-item {
  background-color: $primary !important;
}

.ant-spin-text {
  color: $dark !important;
}

.ant-spin-nested-loading >div>.ant-spin {
  max-height: 100%;
}
