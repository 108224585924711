@import '../../../../commonStyles.scss';

.unsubscribedContainer {
  padding: 60px;
  font-size: $fs-16;

  .cardBlock {
    margin-top: 10px;
    background-color: $light;
    padding: 20px;

    .subscriptionList {
      width: 50%;
    }

    .list-subscribeBtn {
      padding: 9px 35px;
      font-size: 18px;
    }
  }
}